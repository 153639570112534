import { Input } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import React, {useEffect, useState} from 'react';
import { HiOutlineLocationMarker, HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import { useToast } from '@chakra-ui/react';

const NewHome = () => {
    const toast = useToast();
    const { login, currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [user, setUser] = useState({ depo_and_phone: "", password: "" });
    const [loading, setLoading]= useState(false);

    useEffect(() => {
        // if(currentUser !== null){
        //     navigate('/profile');
        // }
    }, []);
    

    const nav = () => {
        navigate('/profile');
    }

    const HandleSubmit = async e => {
        if(user.depo_and_phone === "" || user.password === ""){
          return toast({title: 'Мэдээлэл дутуу',description: "Таны мэдээлэл дутуу байна",status: 'warning',duration: 9000,isClosable: true,}) 
        }
        setLoading(true);
        await login(user)
        setLoading(false);
    }

  return (
    <div className='bg-[#402978] w-full h-screen'>
        <div className='w-full font-Roboto flex justify-center items-center'>
            <div className='flex flex-col justify-center xs:w-full xs:mx-2 md:w-1/3'>
                <div className='flex justify-center py-8'>
                    <img className='xs:h-20 md:h-40' src='../4.png'/>
                </div>
                <div className='bg-white rounded-md py-8 px-8'>
                    <div className=''>
                        <h1 className='text-xl text-center font-bold uppercase'>2020 store тавтай морил</h1>
                        {/* <div className='border-b mt-4'></div> */}
                        <div className='flex justify-center'>
                            <img className='h-10' src='../../icons/hello.png'/>
                        </div>
                    </div>
                    <div>
                        <div className='flex items-center  mt-3'>
                            <div className='bg-white rounded-md border p-2'>
                                <HiOutlinePhone size={16} className=''/>
                            </div>
                            <Input 
                            value={user.depo_and_phone}
                            onChange={ e => setUser({...user, depo_and_phone:e.target.value})} 
                            type='number' 
                            placeholder='Утасны дугаар эсвэл дансны дугаар' className='ml-1 bg-slate-100 md:placeholder:text-sm xs:placeholder:text-[8px]'/>
                        </div>

                        <div className='flex items-center  mt-3'>
                            <div className='bg-white rounded-md border p-2'>
                                <RiLockPasswordLine size={16} className=''/>
                            </div>
                            <Input
                            value={user.password}
                            onChange={ e => setUser({...user, password:e.target.value})}
                            type='password'  placeholder='Нууц үг эсвэл дансны дугаарын сүүлийн 4 оронг оруулна уу' className='ml-1 bg-slate-100 md:placeholder:text-sm xs:placeholder:text-[8px]'/>
                        </div>

                  

                        {
                            loading?
                            <Button className='w-full mt-8 uppercase hover:bg-[#402978]' textColor='white' bg='#402978'>
                                Шалгаж байна ...
                            </Button>
                            :
                            <Button onClick={HandleSubmit} className='w-full mt-8 uppercase hover:bg-[#402978]' textColor='white' bg='#402978'>
                                Нэвтрэх
                            </Button>
                        }

                        <Link to='/register' className='text-blue-800 text-center mt-4'>
                            {/* <Button className='w-full mt-1 uppercase hover:bg-[#053262]' textColor='white' bg='blue'> */}
                                {/* Facebook group */}
                            {/* </Button> */}
                            <h1 className='mt-4 font-bold'>Бүртгүүлэх</h1>
                        </Link>
                        <a href='https://www.facebook.com/groups/256707968539336/' target="_blank" className='text-blue-800 text-center mt-4'>
                            {/* <Button className='w-full mt-1 uppercase hover:bg-[#053262]' textColor='white' bg='blue'> */}
                                {/* Facebook group */}
                            {/* </Button> */}
                            <h1 className='mt-8 font-bold'>Facebook group</h1>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className='flex justify-center mt-4 font-Roboto'>
            <div className='md:w-1/4 flex mx-10 xs:gap-0 md:gap-2 justify-center'>
                <HiOutlineLocationMarker color='white' size={18}/>
                <h1 className='text-white text-xs text-center'>Хаяг: Сбд 10-р хороо 29байр 1 давхарт</h1>
            </div>
        </div>
        <div className='flex justify-center mt-2 font-Roboto'>
            <div className='md:w-1/4 flex mx-6 gap-2 items-center justify-center'>
                <HiOutlinePhone color='white' size={12}/>
                <h1 className='text-white text-xs text-center'>Утас: 8872-1818</h1>
            </div>
        </div>
        <div>
            <h1 className='text-white font-Roboto text-sm text-center mt-20 font-bold uppercase'>Giilen.mn бүтээв</h1>
        </div>
    </div>
  )
}

export default NewHome
