import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDJggai9gWkJZ_2NzQIuLfnF8EeZIasOLA",
  authDomain: "store-c6a5f.firebaseapp.com",
  projectId: "store-c6a5f",
  storageBucket: "store-c6a5f.appspot.com",
  messagingSenderId: "802327417388",
  appId: "1:802327417388:web:2b330944288c5d40743789"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);



