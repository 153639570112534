import React, { useContext } from 'react'
import { RxExit } from "react-icons/rx";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure
  } from '@chakra-ui/react'
import { AuthContext } from '../../context/auth';

const Exit = () => {
 const { isOpen, onOpen, onClose } = useDisclosure()
  const {logout} = useContext(AuthContext);

  const log = () => {
    logout();
  }

  return (
    <>
      <RxExit className='cursor-pointer' color='white' size={20} onClick={onOpen}/>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Гарах</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1 className='font-Roboto '>Та гарахдаа итгэлтэй байна уу ?</h1>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost'  mr={3} onClick={onClose}>
              Болих
            </Button>
            <Button colorScheme='blue' onClick={log}>Тийм</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Exit