import React from 'react'
import { BsFillBoxSeamFill, BsFillBox2HeartFill } from "react-icons/bs";
import { IoBagCheckSharp } from "react-icons/io5";
import { FaHandHoldingHeart } from "react-icons/fa";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios'
import { orders_uri } from '../../uri';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import { Spinner } from '@chakra-ui/react';

const Stats = () => {
    const [data, setData] = useState({all:'0', req:'0', irsen:'0'});
    const {currentUser} = useContext(AuthContext);
    const [load, setLoad] = useState(false);

    const Get = async()=> {
        setLoad(true);
        try{
            const res = await axios.get(orders_uri+`/user-stat?token=${currentUser}`);
            setData({...data, all:res.data.all,req:res.data.reqs,irsen:res.data.irsen});
            setLoad(false);
        }catch(err){
            console.log(err);
            setLoad(false);
        }
    }

    useEffect(() => {
      Get();
    }, []);
    
  return (
    <div className='md:mx-[200px] mt-2 xs:mx-4'>
        <div className='grid xs:grid-cols-2 md:grid-cols-4 gap-2'>
            <div className='bg-white rounded-md p-4 shadow-md flex justify-between items-center border border-yellow-400'>
                <div>
                    {
                        load?
                        <Spinner/>
                        :
                        <h1 className='text-xl font-semibold'>{data.all}</h1>
                    }
                    <h1 className='text-sm'>Нийт захиалга</h1>
                </div>
                <BsFillBoxSeamFill size={30} color='blue'/>
            </div>
            <div className='bg-white rounded-md p-4 shadow-md flex justify-between items-center border border-yellow-400'>
                <div>
                    {
                        load?
                        <Spinner/>
                        :
                        <h1 className='text-xl font-semibold'>{data.req}</h1>
                    }
                    <h1 className='text-sm'>Хүлээгдэж байгаа</h1>
                </div>
                <BsFillBox2HeartFill size={30} color='gray'/>
            </div>
            <div className='bg-white rounded-md p-4 shadow-md flex justify-between items-center border border-yellow-400'>
                <div>
                    {
                        load?
                        <Spinner/>
                        :
                        <h1 className='text-xl font-semibold'>{data.irsen}</h1>
                    }
                    <h1 className='text-sm'>Ирсэн</h1>
                </div>
                <IoBagCheckSharp size={30} color='green'/>
            </div>
            <div className='bg-white rounded-md p-4 shadow-md flex justify-between items-center border border-yellow-400'>
                <div>
                    <h1 className='text-sm'>Манайхыг сонгосон танд</h1>
                    <h1 className='text-xl font-semibold'>БАЯРЛАЛАА</h1>
                </div>
                <FaHandHoldingHeart size={30} color='red'/>
            </div>
        </div>
    </div>
  )
}

export default Stats