import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    Input,
    Spinner,
  } from '@chakra-ui/react'
  import React from 'react'
import { useEffect } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import axios from 'axios';
import { user_uri } from '../../uri';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import { useState } from 'react';

  const NewMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef();
    const {currentUser} = useContext(AuthContext);
    const [user, setUser] = useState();
    const [load, setLoad] = useState(false);

    const Get =async()=> {
      setLoad(true);
      try{
        const res = await axios.get(user_uri+`?token=${currentUser}`);
        setUser(res.data);
        setLoad(false);
      }catch(err){
        console.log(err);
        setLoad(false);
      }
    }

    useEffect(() => {
      Get();
    }, [])
    
  
    return (
      <>
      {/* onClick={onOpen} */}
        <div className='flex items-center xs:gap-1 md:gap-2 cursor-pointer' ref={btnRef} >
            <div className='p-2 bg-white rounded-full'>
                <img className='xs:h-4 md:h-8' src='../../icons/profile.png'/>
            </div>
            {
              load?
              <Spinner/>
              :
              <h1 className='text-white xs:text-sm md:text-md'>{user}</h1>
            }
            {/* <MdKeyboardArrowDown color='white' size={24}/> */}
        </div>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create your account</DrawerHeader>
  
            <DrawerBody>
              <Input placeholder='Type here...' />
            </DrawerBody>
  
            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  export default NewMenu
