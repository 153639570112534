import React from 'react'
import { CiBookmarkPlus } from "react-icons/ci";
import { FaMoneyCheck } from "react-icons/fa";
import { RiTruckFill } from "react-icons/ri";
import { HiLightBulb } from "react-icons/hi";
import { LEASING_TYPES, TYPES } from '../../utils/type';
import moment from "moment";

const Card = ({data}) => {
  return (
    <div className='mt-1'>
        <div className='border border-gray-200 font-Roboto rounded-md p-2 flex justify-between items-center flex-wrap'>
            <div className='flex items-center gap-6'>
                <div className='flex gap-2 items-center'>
                    <CiBookmarkPlus color='green'/>
                    <h1 className='text-md '>{data.product.name}</h1>
                </div>
                {
                    data.leasing === LEASING_TYPES.Y &&
                    <div>
                        <h1 className='text-[10px] text-orange-500 uppercase font-bold'>Лизингээр захиалсан</h1>
                    </div>
                }
            </div>
            <div className='flex items-center xs:gap-2 md:gap-6 mt-4 flex-wrap md:mt-0 justify-between w-full'>
                <div className='flex gap-4'>
                    <div className='flex items-center gap-2'>
                        <HiLightBulb size={18} color='gray'/>
                        <h1 className='text-sm'>ширхэг: <span className='font-bold'>{data.too}</span></h1>
                    </div>
                    <div className='flex items-center gap-2'>
                        <RiTruckFill size={18} color='gray'/>
                        <h1 className='text-sm'>карго: <span className='font-bold'>{Number(data.pay) * Number(data.too)}</span> ₮</h1>
                    </div>
                    <div className='flex items-center gap-2'>
                        <FaMoneyCheck size={18} color='gray'/>
                        {
                            data.prices.id === 1 ?
                            <h1 className='text-sm'>үнэ: <span className='font-bold'>{data.product.price}</span> ₮</h1>
                            :
                            <h1 className='text-sm'>үнэ: <span className='font-bold'>{data.prices.price}</span> ₮</h1>
                        }
                        <h1 className='text-sm'>код: <span className='font-bold'>{data.code}</span></h1>
                    </div>
                </div>
                <div className='flex justify-end'>
                    {data.type === TYPES.IRSEN && (data.delivery === TYPES.REQUESTED) && (
                        <div className='flex items-center gap-2 ml-8 bg-green-600 rounded-md text-white p-2 text-sm'>
                            <h1 className='text-md font-bold uppercase'>Хүргэлтэнд бүртгүүлсэн</h1>
                        </div>
                    )}
                    {data.type === TYPES.IRSEN && (!data.delivery || data.delivery === null) && (
                        <div className='flex items-center gap-2 ml-8 bg-green-600 rounded-md text-white p-2 text-sm'>
                            <h1 className='text-md font-bold uppercase'>Ирсэн</h1>
                        </div>
                    )}
                    {data.type === TYPES.REQUESTED && (
                        <div className='flex items-center gap-2 ml-8 bg-gray-300 rounded-md text-gray-600 text-sm p-2'>
                            <h1 className='text-md font-bold uppercase'>Хүлээгдэж байгаа</h1>
                        </div>
                    )}
                    {data.type === TYPES.REJECTED && (
                        <div className='flex items-center gap-2 ml-8 bg-red-500 rounded-md text-white text-sm p-2'>
                            <h1 className='text-md font-bold uppercase'>
                                {data.leasing === LEASING_TYPES.R ? 'Лизинг цуцлагдсан' : 'Буцаалт хийсэн'}
                            </h1>
                        </div>
                    )}
                    {data.type === TYPES.CONFIRM && (
                        <div className='flex items-center gap-2 ml-8 bg-gray-100 rounded-md text-gray-600 text-sm p-2 ring-1 ring-green-600'>
                            <h1 className='text-md font-bold uppercase'>
                                Хүлээлгэж өгсөн {data.delivery === TYPES.CONFIRM && " (хүргэлтээр)"}
                            </h1>
                        </div>
                    )}
                </div>
            </div>
            {
                data.type === TYPES.IRSEN &&
                <h1>{moment.utc(data.date).format('YYYY-MM-DD')}</h1>
            }
        </div>
    </div>
  )
}

export default Card