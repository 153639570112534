import {Route, Routes} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import NewHome from './pages/NewHome';
import NewProfile from './pages/NewProfile';
import NewRegister from './pages/NewRegister';
import Forgot from './pages/Forgot';

function App() {
  return (
    <>
      <ToastContainer/>
      {/* <Navbar/>
      <Alert/> */}
      <Routes>
        <Route path='/' element={<NewHome/>}/>
        <Route path='/profile' element={<NewProfile/>}/>
        <Route path='/register' element={<NewRegister/>}/>
        {/* <Route path='/forgot' element={<Forgot/>}/> */}
        
        {/* <Route path='/service-procedure' element={<ServiceProcedure/>}/>
        <Route path='/lessons' element={<Lessons/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/forget-password' element={<ResetPass/>}/>
        <Route path='/activate/:token' element={<ActivatePage/>}/>
        <Route path='/address' element={<Address/>}/> */}

        {/* <Route path="/account" element={<Account />}>
          <Route index element={<AccDashboard/>}/>
          <Route path="orders" element={<Orders/>}/>
        </Route>  */}
        
      </Routes>
      {/* <Footer/> */}
    </>
  );
}

export default App;