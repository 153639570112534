import React from 'react';
import { Select, Skeleton, useToast } from '@chakra-ui/react';
import Card from './card';
import { useEffect } from 'react';
import { orders_uri } from '../../uri';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import axios from 'axios';
import Paginations from '../Pagination';
import { TYPES } from '../../utils/type';
import { useNavigate } from 'react-router-dom';
import DeliveryModal from './Delivery/modal';

const NewProducts = () => {
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const toast = useToast();
    const {currentUser} = useContext(AuthContext);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('');
    const [pagination, setPagination] = useState({limit:10, all:'', totalPage:'',phoneNo:''});
    const [load, setLoad] = useState(false);

    const Get = async () => {
        setLoad(true);
        try{
            const res  = await axios.get(orders_uri+`/user?token=${currentUser}&page=${page}&limit=${pagination.limit}&status=${status}`);
            setData(res.data.data)
            setPagination({...pagination, all:res.data.all, totalPage:res.data.totalPage});
            setLoad(false);
        }catch(err){
            console.log(err, "akkaka")
            if(err.response.status === 444){
                nav("/");
                return toast({title: 'Автомат салалт хийгдлээ',description: "Та дахин нэвтрэнэ үү",status: 'error',duration: 9000,isClosable: true,}) 
            }
            setLoad(false);
        }
    }

    useEffect(() => {
      Get();
    }, [page, status]);

    const callback = () => {
        Get();
    }

    const changePage = (page) => {
        setPage(page);
    };

  return (
    <div className='md:mx-[200px] xs:mx-4'>
        <div className='bg-white p-4 mt-4 rounded-md'>
            <h1 className='mt-4'>Төлөв</h1>
            <div className='mt-2'>
                <Select placeholder='Сонгох' className='bg-white' bg='white' onChange={(e)=> setStatus(e.target.value)}>
                    <option value=''>Бүгд</option>
                    <option value={TYPES.REQUESTED}>Хүлээгдэж байгаа</option>
                    <option value={TYPES.IRSEN}>Ирсэн</option>
                    <option value={TYPES.CONFIRM}>Хүлээлгэж өгсөн</option>
                    <option value={TYPES.REJECTED}>Буцаалт хийсэн</option>
                </Select>
            </div>
            <div>
                {
                    status === TYPES.IRSEN &&
                    <div>
                        {
                            data.length !== 0 &&
                            <div>
                                <DeliveryModal data={data.length} callback={callback}/>
                            </div>
                        }
                    </div>
                }
            </div>
            <h1 className='mt-6'>Захиалгууд</h1>
            {
                load?
                    <div>
                        <Skeleton className='w-full h-16 rounded-xl shadow-sm mt-2'/>
                        <Skeleton className='w-full h-16 rounded-xl shadow-sm mt-2'/>
                        <Skeleton className='w-full h-16 rounded-xl shadow-sm mt-2'/>
                    </div>
                :
                <div className='mt-2'>
                    <div>
                        {
                            data.map((it, index) => {
                                return(
                                    <Card key={index} data={it}/>
                                )
                            })
                        }
                        {
                            data.length === 0 &&
                            <div className='bg-white pt-8 text-center flex items-center justify-center flex-col'>
                                <img className='h-24' src='https://cdn.dribbble.com/users/665029/screenshots/16162764/media/3ea69cb1655fba401acc6c4328d38633.gif'/>
                                <h1 className=''>Хоосон байна...</h1>
                            </div>
                        }
                        <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/> 
                    </div>
                </div>
            }
        </div>
    </div>
  )
}

export default NewProducts