import { Input } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import React, {useState} from 'react';
import { HiOutlineDesktopComputer, HiOutlineLocationMarker, HiOutlinePhone } from 'react-icons/hi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { registerUri } from '../../uri';
import { useToast } from '@chakra-ui/react'

const NewRegister = () => {
    const navigate = useNavigate();
    const toast = useToast()
    const [user, setUser] = useState({deposit:'', phone_no:'', password:'', password1:''});
    const [load, setLoad] = useState(false);

    const nav = () => {
        navigate('/profile');
    }

    const Submit = async() => {
        if(user.password !== user.password1) return toast({title: 'Таны нууц үг таарахгүй байна !',status: 'error',duration: 8000,isClosable: true,})

        setLoad(true);
        try{
            const res = await axios.post(registerUri, user);
            if(res.status === 209){
                setLoad(false);
                return toast({title: 'Таны утасны дугаар эсвэл дансны дугаар аль хэдийн бүртгэлтэй байна !',status: 'error',duration: 8000,isClosable: true,})
            }
            if(res.status === 201){
                setLoad(false);
                navigate('/');
                return toast({title: 'Амжилттай бүртгүүллээ',status: 'success',duration: 8000,isClosable: true,})
            }
            setLoad(false);
        }catch(err){
            setLoad(false);
            console.log(err);
        }
    }


  return (
    <div className='bg-[#402978] w-full h-[100vh]'>
        <div className='w-full font-Roboto flex justify-center items-center'>
            <div className='flex flex-col justify-center xs:w-full xs:mx-2 md:w-1/3'>
                <div className='flex justify-center py-8'>
                    <img className='xs:h-20 md:h-40' src='../4.png'/>
                </div>
                <div className='bg-white rounded-md py-8 px-8'>
                    <div className=''>
                        <h1 className='text-xl text-center font-bold uppercase'>Бүртгүүлэх хуудас</h1>
                        {/* <div className='border-b mt-4'></div> */}
                        <div className='flex justify-center'>
                            <img className='h-10' src='../../icons/hello.png'/>
                        </div>
                    </div>
                    <div>
                        <div className='flex items-center  mt-3'>
                            <div className='bg-white rounded-md border p-2'>
                                <HiOutlinePhone size={16} className=''/>
                            </div>
                            <Input 
                            value={user.phone_no}
                            onChange={ e => setUser({...user, phone_no:e.target.value})} 
                            type='number' 
                            placeholder='Утасны дугаар' className='ml-1 bg-slate-100'/>
                        </div>
                        <div className='flex items-center  mt-3'>
                            <div className='bg-white rounded-md border p-2'>
                                <HiOutlineDesktopComputer size={16} className=''/>
                            </div>
                            <Input 
                            value={user.deposit}
                            onChange={ e => setUser({...user, deposit:e.target.value})} 
                            type='number' 
                            placeholder='Дансны дугаар' className='ml-1 bg-slate-100'/>
                        </div>

                        <div className='flex items-center  mt-3'>
                            <div className='bg-white rounded-md border p-2'>
                                <RiLockPasswordLine size={16} className=''/>
                            </div>
                            <Input
                            value={user.password}
                            onChange={ e => setUser({...user, password:e.target.value})}
                            type='password' placeholder='Нууц үг' className='ml-1 bg-slate-100'/>
                        </div>

                        <div className='flex items-center  mt-3'>
                            <div className='bg-white rounded-md border p-2'>
                                <RiLockPasswordLine size={16} className=''/>
                            </div>
                            <Input
                            value={user.password1}
                            onChange={ e => setUser({...user, password1:e.target.value})}
                            type='password' placeholder='Нууц үг давтах' className='ml-1 bg-slate-100'/>
                        </div>

                        {
                            load?
                            <Button className='w-full mt-8 uppercase hover:bg-[#053262]' textColor='white' bg='#402978'>
                                Уншиж байна ...
                            </Button>
                            :
                            <Button onClick={Submit} className='w-full mt-8 uppercase hover:bg-[#053262]' textColor='white' bg='#402978'>
                                Бүртгүүлэх
                            </Button>
                        }
                        <Link to='/' className='text-blue-800 text-center mt-4'>
                            <h1 className='mt-4 font-bold'>Нэвтрэх</h1>
                        </Link>
                        <a href='https://www.facebook.com/groups/256707968539336/' target="_blank" className='text-blue-800 text-center mt-4'>
                            <h1 className='mt-8 font-bold'>Facebook group</h1>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className='flex justify-center mt-4 font-Roboto'>
            <div className='md:w-1/4 flex mx-10 xs:gap-0 md:gap-2 justify-center'>
                <HiOutlineLocationMarker color='white' size={18}/>
                <h1 className='text-white text-xs text-center'>Хаяг: Сбд 10-р хороо 29байр 1 давхарт</h1>
            </div>
        </div>
        <div className='flex justify-center mt-2 font-Roboto pb-10'>
            <div className='md:w-1/4 flex mx-6 gap-2 items-center justify-center'>
                <HiOutlinePhone color='white' size={12}/>
                <h1 className='text-white text-xs text-center'>Утас: 8872-1818</h1>
            </div>
        </div>
    </div>
  )
}

export default NewRegister