import React, { useContext } from 'react'
import { Link, Navigate } from 'react-router-dom'
import NewMenu from '../../components/NewMenu';
import Exit from '../../components/Exit';
import Stats from '../../components/Stats';
import NewProducts from '../../components/NewProducts';
import { AuthContext } from '../../context/auth';
import AddressAdd from './address-add';


const NewProfile = () => {
  const {currentUser} = useContext(AuthContext);

  if(currentUser){
    return (
      <div className='bg-gray-200 h-[100vh]'>
        <div className='bg-gray-200 font-Roboto h-full w-full'>
            <div className='bg-[#402978]'>
                <div className='flex items-center justify-between md:mx-[200px] xs:mx-4 py-7'>
                  <div>
                    <img className='xs:h-12 md:h-24' src='../../4.png'/>
                  </div>
                  {/* <h1 className='xs:text-sm md:text-xl font-bold text-white '>Welcome</h1> */}
                  <div className='flex items-center gap-4'>
                    <Exit/>
                    <NewMenu/>
                  </div>
                </div>
            </div>
            
            <div className='md:mx-[200px] xs:mx-4 mt-[-20px]'>
              <div className='p-4 bg-white shadow-md rounded-md flex justify-between items-center'>
                <Link to='/profile' className=''>Захиалгын ерөнхий мэдээлэл</Link>
                {/* <AddressAdd/> */}
              </div>
            </div>
            <Stats/>
            <NewProducts/>
            {/* <h1 className='font-Roboto text-sm text-center mt-80'>Giilen.mn бүтээв</h1> */}
        </div>
      </div>
    )
  }else{
    return <Navigate to='/'/>
  }
}

export default NewProfile
